/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');

/* Base Colors */
:root {
    --primary-color: #448EF7;
    --secondary-color: #37C2E6;
    --accent-color: #F7B645;
    --highlight-color: #4CAF50;
    --dark-gray: #333333;
    --light-gray: #F0F0F0;
}

/* Body and General Styles */
html, body {
    height: 100%;
    margin: 0;
    font-family: 'Inter', sans-serif;
    color: var(--dark-gray);
    background-color: var(--light-gray);
    line-height: 1.6;
}

/* App container that wraps the whole application */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Header Styles */
.header {
    background-color: var(--primary-color);
    color: white;
    padding: 5px 20px;
    text-align: center;
    position: relative;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.header-left {
    display: flex;
    align-items: center;
}

.credits-text {
    margin-left: 15px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: white;
}

.hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    cursor: pointer;
}

.hamburger-line {
    width: 25px;
    height: 2px;
    background-color: white;
    margin: 3px 0;
}

.header-link {
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.header h1 {
    margin: 0;
}

/* Footer Styles */
.footer {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    margin-top: auto;
}

.footer-text {
    margin: 0;
}

.footer-text a {
    color: white;  /* Link color (Bootstrap primary blue) */
    text-decoration: none;
  }

.footer-link {
    color: white;
    text-decoration: none;
    margin-left: 5px;
}

.footer-link:hover {
    color: var(--secondary-color);
}

/* Sidebar and Dashboard Styles */
.dashboard {
    display: flex;
    height: 100vh;
    overflow: hidden;
    margin: 0;
}

.sidebar {
    flex: 0 0 250px;
    background-color: #f4f4f4;
    height: 100%;
    overflow-y: auto;
    transition: width 0.3s ease;
}

.sidebar.collapsed {
    width: 60px;
    height: 100%;
    background-color: #f4f4f4;
}

.main-content-wrapper {
    flex-grow: 1;
    padding: 20px;
    transition: margin-left 0.3s ease;
    margin-left: 10px;
    overflow-y: auto;
    flex-direction: column;
}

.main-content-wrapper.expanded {
    margin-left: 5px;
}

/* Sidebar Styles */
.sidebar .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
}

.sidebar .sidebar-header h2 {
    color: #333;
    font-size: 1.5rem;
    margin: 0;
}

.sidebar .sidebar-header .toggle-button {
    color: #333;
    font-size: 1.5rem;
}

.sidebar .topics-list .topic-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sidebar .topics-list .topic-item .delete-icon {
    display: none;
    color: red;
    font-size: 1.2rem;
    cursor: pointer;
}

.sidebar .topics-list .topic-item.hovered .delete-icon {
    display: inline-block;
}

.sidebar .topics-list .topic-item:hover {
    background-color: #f0f0f0;
}

.sidebar .search-box {
    margin: 5px 0;
    padding: 5px;
}

.search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.topic-item.hovered {
    background-color: #f0f0f0;
}

.new-topic-button {
    width: calc(100% - 30px);
    margin: 10px 15px;
    padding: 10px;
    background-color: #1976d2;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
}

/* Main Content Styles */
.main-content {
    flex-grow: 1;
    padding: 20px;
    transition: margin-left 0.3s ease;
    background-color: #fff;
    overflow-y: auto;
    padding-bottom: 20px; 
    min-height: calc(100vh - 80px); /* Adjust for header and footer height */
}

.conversation-item.user-message {
    text-align: right;
    background-color: #f0f8ff;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
}

.conversation-item.assistant-message {
    text-align: left;
    background-color: #f5f5f5;
    padding: 10px;
    margin: 5px 0;
    border-radius: 8px;
}

.suggested-questions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.suggested-questions-container button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.suggested-questions-container button:hover {
    background-color: #0056b3;
}

.default-view h2,
.conversation-view h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
}

.ask-box,
.follow-up-box {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fafafa;
    margin-bottom: 20px;
}

.ask-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    padding: 10px;
    resize: none;
}

.ask-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.ask-button {
    background-color: #1976d2;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

.ask-button:hover {
    background-color: #145a8d;
}

.file-upload {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.upload-icon {
    font-size: 24px;
    color: #888;
}

.conversation-item {
    margin-bottom: 20px;
}

.conversation-item p {
    margin: 5px 0;
    font-family: 'Inter', sans-serif;
}

.markdown-content {
    white-space: pre-wrap;
}

/* Button Styles */
.button-primary {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
}

.button-primary:hover {
    background-color: var(--secondary-color);
}

.button-accent {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
}

.button-accent:hover {
    background-color: #e6a63b;
}

/* Card Styles */
.card {
    background-color: white;
    border: 1px solid var(--light-gray);
    padding: 20px;
    margin: 10px 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
}

/* Highlight Text */
.highlight {
    color: var(--highlight-color);
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

/* Link Styles */
a {
    color: var(--primary-color);
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}

a:hover {
    color: var(--secondary-color);
}

/* Input Field Styles */
input, textarea {
    border: 1px solid var(--light-gray);
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}

input:focus, textarea:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* Utility Classes */
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.pt-10 {
    padding-top: 10px;
}

.pb-10 {
    padding-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .ask-box,
    .follow-up-box {
        padding: 10px;
    }

    .ask-input {
        font-size: 14px;
    }

    .ask-button {
        font-size: 16px;
        padding: 8px 16px;
    }

    .upload-icon {
        font-size: 20px;
    }
}
